import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useMemo, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import styles from "./Tab.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saveVenuesParams } from "../../../../redux/venues/updateSearchParamsVenues";
import inputStyle from "../../../../common/styles/inputStyle";
import AttendeesBedrooms from "../DropdownSearchComponents/AttendeesBedrooms/AttendeesBedrooms";
import VenueTypeFacilities from "../../../../common/components/DropdownComponents/VenueTypeFacilitiesFinal/VenueTypeFacilities";
import { fetchVenues } from "../../../../redux/venues/venuesSearch";
import store from "../../../../redux/store";

const VenuesTab = ({ isList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedEvent, setSelectedEvent] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [attendees, setAttendees] = useState("");
  const [bedrooms, setBedrooms] = useState(0);
  const [conferenceRooms, setConferenceRooms] = useState("");
  const [open, setOpen] = useState(false);

  const events = useSelector((state) => state.eventTypes.eventTypes);
  const cities = useSelector((state) => state.cities.cities);

  const [selections, setSelections] = useState({
    checkedVenueTypes: localStorage.getItem("checkedVenueTypes") || [],
    checkedAmenities: localStorage.getItem("checkedAmenities") || [],
  });

  const eventsOptions = useMemo(
    () => events.map((event) => ({ label: event.title, value: event.id })),
    [events]
  );
  const citiesOptions = useMemo(
    () => cities.map((city) => ({ label: city.name, value: city.id })),
    [cities]
  );

  useEffect(() => {
    if (isList) {
      const storedSearchParamsString =
        localStorage.getItem("venuesSearchParams");
      if (storedSearchParamsString) {
        const storedSearchParams = JSON.parse(storedSearchParamsString);

        const storedCityID = storedSearchParams?.cities;

        if (storedCityID) {
          // const matchingCity = citiesOptions.find(
          //   (cityOption) => Number(cityOption.value) === Number(storedCityID)
          // );
          // console.log("MATCHING CITY", matchingCity);
          // if (matchingCity) setSelectedCity(matchingCity);
          const matchingCities = citiesOptions.filter((cityOption) =>
            storedCityID.includes(cityOption.value)
          );
          setSelectedCity(matchingCities);
        }

        const storedEventID = storedSearchParams?.events;
        if (storedEventID) {
          // const matchingEvent = eventsOptions.find(
          //   (eventOption) => Number(eventOption.value) === Number(storedEventID)
          // );
          // if (matchingEvent) setSelectedEvent(matchingEvent);
          const matchingEvents = eventsOptions.filter((eventOption) =>
            storedEventID.includes(eventOption.value)
          );
          setSelectedEvent(matchingEvents);
        }

        if (storedSearchParams.total_number_of_people)
          setAttendees(Number(storedSearchParams.total_number_of_people));
        if (storedSearchParams.total_number_of_conference_rooms)
          setConferenceRooms(
            Number(storedSearchParams.total_number_of_conference_rooms)
          );
        if (storedSearchParams.total_guest_rooms)
          setBedrooms(Number(storedSearchParams.total_guest_rooms));
        if (
          storedSearchParams.venue_categories ||
          storedSearchParams.amenities_lists
        ) {
          const venueTypes =
            JSON.parse(localStorage.getItem("checkedVenueTypes")) || [];

          const amenities =
            JSON.parse(localStorage.getItem("checkedAmenities")) || [];

          setSelections({
            checkedVenueTypes: venueTypes,
            checkedAmenities: amenities,
          });
        }
      }
    }
  }, [isList]);

  const createPostRequestBody = () => {
    const checkedVenueTypes =
      JSON.parse(localStorage.getItem("checkedVenueTypes")) || [];
    const checkedAmenities =
      JSON.parse(localStorage.getItem("checkedAmenities")) || [];

    const mappedCities = selectedCity.map((city) => city.value);
    const mappedEvents = selectedEvent.map(
      (selectedEvent) => selectedEvent.value
    );
    return {
      cities: selectedCity ? mappedCities : [],
      events: selectedEvent ? mappedEvents : [],
      total_number_of_people: Number(attendees),
      total_number_of_conference_rooms: Number(conferenceRooms),
      total_guest_rooms: Number(bedrooms),
      venue_categories: checkedVenueTypes,
      amenities_lists: checkedAmenities,
    };
  };

  const handleVenuesSearch = () => {
    const postData = createPostRequestBody();

    localStorage.setItem("venuesSearchParams", JSON.stringify(postData));
    dispatch(saveVenuesParams(postData));

    dispatch(fetchVenues(postData, "search-venues", "venues"));
    if (!isList) navigate("/search");
  };

  const handleNumberOfPeopleClick = () => {
    setOpen((prev) => !prev);
  };

  const divRef = useRef();
  const dropdownRef = useRef();

  const increaseAttendees = () => setAttendees((prev) => prev + 1);
  const decreaseAttendees = () =>
    setAttendees((prev) => (prev > 0 ? prev - 1 : 0));
  const increaseBedrooms = () => setBedrooms((prev) => prev + 1);
  const decreaseBedrooms = () =>
    setBedrooms((prev) => (prev > 0 ? prev - 1 : 0));

  useEffect(() => {
    const closeDropdown = (e) => {
      if (divRef.current !== e.target && dropdownRef.current !== null) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  const container = isList
    ? `${styles.container} ${styles.list}`
    : styles.container;
  const handleResetFilters = () => {
    setSelectedEvent([]);
    setSelectedCity([]);
    setAttendees(0);
    setBedrooms(0);
    setConferenceRooms(0);
    setSelections({
      checkedVenueTypes: [],
      checkedAmenities: [],
    });

    localStorage.removeItem("cities");
    localStorage.removeItem("total_number_of_people");
    localStorage.removeItem("total_guest_rooms");
    localStorage.removeItem("total_number_of_conference_rooms");
    localStorage.removeItem("venuesSearchParams");

    localStorage.removeItem("checkedVenueTypes");
    localStorage.removeItem("checkedAmenities");
  };

  return (
    <div className={container}>
      <Autocomplete
        multiple
        limitTags={1}
        sx={inputStyle}
        disablePortal
        options={citiesOptions}
        value={selectedCity}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(_, value) => {
          setSelectedCity(value);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Venue, City, Location" />
        )}
      />

      <Autocomplete
        multiple
        limitTags={1}
        sx={inputStyle}
        disablePortal
        options={eventsOptions}
        value={selectedEvent}
        getOptionLabel={(event) => event.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(_, value) => setSelectedEvent(value)}
        renderInput={(params) => (
          <TextField {...params} label="Type of Event" />
        )}
      />

      {!isList && (
        <TextField
          id="number-of-persons"
          label="Number of Persons"
          type="number"
          sx={inputStyle}
          value={attendees}
          onChange={(e) => setAttendees(e.target.value)}
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: "0" }}
        />
      )}

      <TextField
        id="conference-rooms"
        label="Conference Rooms"
        type="number"
        sx={inputStyle}
        value={conferenceRooms}
        onChange={(e) => setConferenceRooms(e.target.value)}
        InputLabelProps={{ shrink: true }}
        inputProps={{ min: "0" }}
      />

      {isList && (
        <>
          <div
            ref={divRef}
            className={styles.numOfPeople}
            onClick={handleNumberOfPeopleClick}
          >
            {attendees} attendees | {bedrooms} bedrooms
            {open && (
              <AttendeesBedrooms
                attendees={attendees}
                bedrooms={bedrooms}
                onIncreaseAttendees={increaseAttendees}
                onDecreaseAttendees={decreaseAttendees}
                onIncreaseBedrooms={increaseBedrooms}
                onDecreaseBedrooms={decreaseBedrooms}
                setAttendees={setAttendees}
                setBedrooms={setBedrooms}
                ref={dropdownRef}
              />
            )}
          </div>
          <VenueTypeFacilities
            selections={selections}
            setSelections={setSelections}
          />
        </>
      )}
      <button
        className={`${styles.btn} ${styles.reverse}`}
        onClick={handleResetFilters}
      >
        Reset Filters
      </button>
      <button className={styles.btn} onClick={handleVenuesSearch}>
        Find Venues
      </button>
    </div>
  );
};

export default VenuesTab;
