import styles from "./ListItem.module.css";
import GraySubtitle from "../../../../../../../common/components/GraySubtitle/GraySubtitle";
import Title from "../../../../../../../common/components/Title/Title";
import { Link, useLocation } from "react-router-dom";
import Button from "../../../../../../../common/components/Button/Button";
import BedIcon from "@mui/icons-material/Bed";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addVenueToShortlist,
  removeVenueFromShortlist,
} from "../../../../../../../redux/slices/shortlistSlice";
import { Carousel } from "react-responsive-carousel";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import WhatsAppOverlay from "../../../../PreviewPage/components/WhatsAppOverlay/WhatsAppOverlay";
import { useNavigate } from "react-router-dom";
import { blogAxios, miceAxios } from "../../../../../../../axios/axios";
import Conference from "../../../../../../../common/assets/svg-mice/conference_room.svg";

const iconStyle = {
  fill: "var(--clr-purple)",
  width: "30px",
  height: "30px",
};

const arrowStyle = {
  color: "white",
  backgroundColor: "rgba(100, 100, 111, 0.3)",
  position: "absolute",
  zIndex: "200",
  top: "50%",
  borderRadius: "50%",
  border: "0",
  outline: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.1)",
};

const ListItem = ({ item, handleOpen }) => {
  const slug = item.slug ? item.slug : "venue";
  const [galleries, setGalleries] = useState([]);
  const [shortlistText, setShortlistText] = useState("Shortlist");
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname.startsWith("/unit");

  const shortlistedVenues = useSelector((state) => state.shortlist);
  const isShortlisted = shortlistedVenues.some((venue) => venue.id === item.id);
  const handleAddToShortlist = () => {
    if (shortlistText === "Shortlist") {
      setShortlistText("Shortlisted");
      dispatch(addVenueToShortlist(item));
    } else {
      setShortlistText("Shortlist");
      dispatch(removeVenueFromShortlist(item));
    }
  };
  const customPrevArrow = (onClickHandler, hasPrev, label) =>
    hasPrev && (
      <button
        onClick={onClickHandler}
        type="button"
        aria-label={label}
        style={{ ...arrowStyle, left: "10px" }}
      >
        <NavigateBeforeIcon />
      </button>
    );

  const navigate = useNavigate();
  // const navigateToVenuePreview = (itemId) => {
  //   console.log("click");
  //   navigate(`/venues/preview/${itemId}`);
  // };
  const navigateToVenuePreview = (id) => {
    window.open(`/search/${slug}`, "_blank");
  };
  const customNextArrow = (onClickHandler, hasNext, label) =>
    hasNext && (
      <button
        onClick={onClickHandler}
        type="button"
        aria-label={label}
        style={{ ...arrowStyle, right: "10px" }}
      >
        <NavigateNextIcon />
      </button>
    );

  const gallery = !pathname ? item.photo_galleries : item.photo_gallery;

  const fetchImageUrls = async (imageIds) => {
    try {
      const response = await blogAxios.get("getAllFiles");
      const allImages = response.data;
      const filteredImages = allImages.filter((image) =>
        imageIds.includes(image.id)
      );

      setGalleries(filteredImages.slice(0, 10));
    } catch (error) {
      console.error("Error fetching image URLs:", error);
    }
  };

  useEffect(() => {
    const fetchGalleries = async () => {
      try {
        const galleryResponses = await Promise.all(
          item.photo_galleries.map((gallery) =>
            miceAxios.post("get-gallery-images", { gallery_id: gallery.id })
          )
        );
        const galleryImages = galleryResponses.map(
          (response) => response.data.images
        );
        // Flatten the array of arrays
        const imageIds = [].concat
          .apply([], galleryImages)
          .map((image) => image.image_id);
        fetchImageUrls(imageIds);
      } catch (error) {
        console.error("Error fetching galleries:", error);
      }
    };

    fetchGalleries();
  }, [item.photo_galleries]);

  return (
    <div className={styles.listItemContainer}>
      <div
        style={{
          width: !pathname ? "100%" : "max-content",
          height: "200px",
          position: "relative",
        }}
      >
        <Carousel
          autoPlay={false}
          infiniteLoop={false}
          interval={5000}
          showThumbs={false}
          showStatus={false}
          renderArrowNext={customNextArrow}
          renderArrowPrev={customPrevArrow}
          emulateTouch={true}
          onClickItem={(index, item) => {
            if (index !== gallery.length - 1 || gallery.length === 1) {
              const carouselImage = item.props.children[0];

              if (carouselImage.type === "img") {
                navigateToVenuePreview(item.id);
              }
            }
          }}
        >
          {galleries &&
            galleries.length !== 0 &&
            galleries.map((image, index) => {
              return (
                <div key={image.id} style={{ cursor: "pointer" }}>
                  <img
                    id={image.id}
                    src={image.path}
                    alt={image.alt_image_me}
                    className={styles.sliderImage}
                  />

                  {(index === galleries.length - 1 ||
                    galleries.length === 1) && <WhatsAppOverlay />}
                </div>
              );
            })}
        </Carousel>
      </div>
      <div className={styles.rightContainer}>
        <Link to={`/venues/${slug}`} target="_blank">
          {" "}
          <Title text={item.name} fw={400} color={"black"} />
        </Link>
        {item.description && <GraySubtitle 
  text={item.description.split(' ').length > 25 
    ? `${item.description.split(' ').slice(0, 25).join(' ')}...` 
    : item.description
  }
/>

}
        <div className={styles.venueButtons}>
          {item.total_number_of_conference_rooms && (
            <div className={styles.venueInfo}>
              {" "}
              {item.total_number_of_conference_rooms}
              <MeetingRoomIcon style={iconStyle} />
            </div>
          )}
          <div className={styles.line}></div>
          {item.total_number_of_people && (
            <div className={styles.venueInfo}>
              {" "}
              {item.total_number_of_people}
              <img src={Conference} alt="conference" style={iconStyle}/>
              </div>
          )}
          <div className={styles.line}></div>
          {item.total_guest_rooms && (
            <div className={styles.venueInfo}>
              {" "}
              {item.total_guest_rooms}
              <BedIcon style={iconStyle} />
            </div>
          )}
        </div>

        <div className={styles.buttonsContainer}>
          <div className={styles.buttonsContainerVariant}>
            <Button onClick={() => handleOpen(item.id)}>Send Inquiry</Button>
            <Link to={`/venues/${slug}`} target="_blank">
              <Button variant={"outlined"} color={"purple"}>
                View More
              </Button>
            </Link>

            <Button
              variant={isShortlisted ? "" : "outlined"}
              color={shortlistText === "Shortlisted" ? "pink" : "purple"}
              onClick={handleAddToShortlist}
              disabled={shortlistedVenues.length === 10}
            >
              {isShortlisted ? "Shortlisted" : "Shortlist"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
